import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ScEmployerNurseryHeroLeft = styled(motion.div)`
	padding: 15px 30px;
	box-sizing: border-box;
	background: ${({ theme }) => theme.colors.blueberry};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	width: 100%;
	height: 100%;
	img {
		width: auto;
		max-width: 70%;
		height: 70%;
		object-fit: contain;
	}
	max-height: 40svh;
	@media (min-width: 390px) {
		img {
			height: 100%;
		}
	}
	@media (min-width: 768px) {
		max-height: 100%;
		img {
			width: 70%;
			height: auto;
		}
	}
	@media (min-width: 1024px) {
		height: 100%;
		width: 100%;
		img {
			width: 80%;
			height: auto;
		}
	}
	@media (min-width: 1220px) {
		padding: 30px 100px;
		border-radius: 30px;
		height: 100%;
		img {
			height: 60vh;
			width: auto;
		}
	}
	@media (min-width: 1220px) and (max-width: 1919px) and (min-height: 500px) and (max-height: 900px) {
		padding: 15px 50px;
	}
`;

export const ScEmployerNurseryHeroRight = styled(motion.div)`
	padding: 25px 10px;
	height: 100%;
	width: 100%;
	@media (min-width: 390px) {
		padding: 25px;
	}
	box-sizing: border-box;
	background: ${({ theme }) => theme.colors.marmelade};
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	border-radius: 10px;
	.buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 12px;
		margin-top: auto;
	}
	h3 {
		color: ${({ theme }) => theme.colors.strongWhite};
		text-align: center;
		margin: 0;
		font: ${({ theme }) => theme.fonts.heading.small};
		@media (min-width: 1024px) {
			font: ${({ theme }) => theme.fonts.hero.medium};
			text-align: left;
			.buttons {
				gap: 24px;
			}
		}
		@media (min-width: 1220px) {
			font: ${({ theme }) => theme.fonts.largeHeading.largeSmall};
		}
		@media (min-width: 1220px) and (max-width: 1919px) and (min-height: 500px) and (max-height: 900px) {
			font: ${({ theme }) => theme.fonts.heading.large};
		}
	}
	@media (min-width: 1024px) {
		height: 100%;
		width: 100%;
		.buttons {
			margin-top: auto;
		}
	}
	@media (min-width: 1220px) {
		padding: 32px 42px 54px 42px;
		border-radius: 30px;
		height: 100%;
		gap: 64px;
		align-items: start;
		> div {
			max-width: 350px;
		}
	}
	@media (min-width: 1220px) and (max-width: 1919px) and (min-height: 500px) and (max-height: 900px) {
		padding: 32px;
		gap: 20px;
	}
`;
