import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ScEmployerHeroWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
	height: 100%;
	@media (min-width: 1024px) {
		gap: 20px;
		display: grid;
		grid-template-columns: 105fr 75fr;
	}
`;

export const ScEmployerCyclingHeroLeft = styled(motion.div)`
	padding: 38px 60px;
	box-sizing: border-box;
	background: ${({ theme }) => theme.colors.marmelade};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	img {
		width: 100%;
		height: auto;
	}
	@media (min-width: 768px) {
		img {
			width: 85%;
			height: auto;
		}
	}
	@media (min-width: 1024px) {
		height: 100%;
		width: 100%;
	}
	@media (min-width: 1220px) {
		padding: 30px 100px;
		border-radius: 30px;
		height: 100%;
	}
	@media (min-width: 1220px) and (max-width: 1919px) and (min-height: 500px) and (max-height: 900px) {
		padding: 15px 50px;
		img {
			width: 80%;
		}
	}
`;

export const ScEmployerHeroRight = styled(motion.div)`
	padding: 25px 10px;
	height: 100%;
	width: 100%;
	@media (min-width: 390px) {
		padding: 25px;
	}
	box-sizing: border-box;
	background: ${({ theme }) => theme.colors.peach};
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	border-radius: 10px;
	button {
		margin-top: 10px;
	}
	.buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 12px;
		margin-top: auto;
	}
	h1 {
		color: ${({ theme }) => theme.colors.darkText};
		text-align: center;
		margin: 0;
		font: ${({ theme }) => theme.fonts.heading.small};
		@media (min-width: 1024px) {
			font: ${({ theme }) => theme.fonts.hero.medium};
			text-align: left;
		}
		@media (min-width: 1220px) {
			font: ${({ theme }) => theme.fonts.largeHeading.largeSmall};
		}
		@media (min-width: 1220px) and (max-width: 1919px) and (max-height: 930px) {
			font: ${({ theme }) => theme.fonts.heading.large};
		}
	}
	@media (min-width: 1024px) {
		height: 100%;
		width: 100%;
	}
	@media (min-width: 1220px) {
		padding: 32px 42px 54px 42px;
		border-radius: 30px;
		height: 100%;
		gap: 64px;
		align-items: start;
		.buttons {
			gap: 24px;
			margin-top: auto;
		}
		> div {
			max-width: 100%;
		}
	}
	@media (min-width: 1920px) {
		gap: 64px;
		> div {
			max-width: 350px;
		}
		button {
			margin-top: 70px;
		}
	}
	@media (min-width: 1220px) and (max-width: 1919px) and (max-height: 900px) {
		gap: 20px;
		padding: 32px;
	}
`;

export const ScSignUpWrapper = styled.div`
	background: ${({ theme }) => theme.colors.marmelade};
	border-radius: 10px;
	padding: 30px 15px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	margin-bottom: 8px;
	@media (min-width: 1220px) {
		border-radius: 30px;
		padding: 100px;
		margin-bottom: 20px;
	}
`;

export const ScSignUpTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	button {
		margin-top: 10px;
	}
	max-width: 465px;
	@media (min-width: 1220px) {
		gap: 50px;
	}
`;

export const ScBrownWrapper = styled.div`
	background: ${({ theme }) => theme.colors.toasted};
	border-radius: 10px;
	padding: 24px 15px 50px 15px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 24px;
	img {
		width: 280px;
		height: auto;
	}
	@media (min-width: 1220px) {
		border-radius: 30px;
		padding: 45px 30px 50px 60px;
		display: grid;
		grid-template-columns: 68fr 92fr;
		gap: 90px;
		align-items: center;
		img {
			width: 100%;
			height: auto;
		}
	}
	@media (min-width: 1440px) {
		border-radius: 30px;
		padding: 45px 30px 50px 60px;
		display: grid;
		grid-template-columns: 68fr 92fr;
		gap: 90px;
		align-items: center;
		img {
			width: 100%;
			height: auto;
		}
	}
	@media (min-width: 1920px) {
		border-radius: 30px;
		padding: 50px 60px;
		display: grid;
		grid-template-columns: 68fr 92fr;
		gap: 90px;
		img {
			width: 100%;
			height: auto;
		}
	}
`;

export const ScBrownTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	button {
		margin-top: 10px;
	}
	@media (min-width: 1220px) {
		align-items: start;
		gap: 60px;
		button {
			margin-top: 5px;
		}
	}
`;
